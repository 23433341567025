const campaign_model = {
	"name": '',
	"advertiser": '',
	"industry_id": '',
	"campaign_type_id": '',
	"start": '',
	"end": '',
	"account_id": '',
	"campaign_video": '',
	"language": "",
	"theme": "",
	"campaign_types_view_id": '',

	"can_edit": true,
	"contacts": [
		//{"name": "", "lastname": "", "email": "", "phone": ""}
	],
	"creatives": [
		{ "creative": "", "views": 0, "campaign_creative_id": null }
	],
	"sites": [
		{ "site": "", "views": 0, "campaign_site_id": null }
	],

	"surveys": [{
		"campaign_survey_id": null,
		"sort": 0,
		"description": "",
		"alternatives_random": "",
		"survey_type_id": "",
		"alternatives_types": "",
		"alternatives": [
			{ "alternative": "", "correct": "1" }
		],
		"answers": 0
	}],
	"filter_survey": {
		"campaign_survey_id": null,
		"sort": 0,
		"description": "",
		"alternatives_random": "0",
		"survey_type_id": "7",
		"alternatives_types": "radio",
		"alternatives": [
			{ "alternative": "", "correct": "1" }
		],
		"answers": 0
	}
};

export default campaign_model;
