import React, { Component } from 'react'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import { changeCampaignData, getSurveyTypes } from '../../../actions/campaignActions'
import { translate } from '../../../components/Translate'

export class FilterSurvey extends Component {
	constructor(props) {
		super(props);
		this.onChange = this.onChange.bind(this);
		this.onChangeAlternativa = this.onChangeAlternativa.bind(this);
	}

	async componentDidMount() {
		this.setState({ "update": true });
	}

	onChange(e, index) {
		let data = this.props.campaignData
		data.filter_survey[e.target.name] = e.target.value;

		this.props.changeCampaignData(data);
		this.setState({ update: true });
	}


	_addAlternativa = () => {
		let data = this.props.campaignData
		data.filter_survey.alternatives.push({ alternative: '', correct: "0" })
		this.props.changeCampaignData(data);
		this.setState({ update: true });
	}

	_removeAlternativa = (index) => {
		let data = this.props.campaignData
		data.filter_survey.alternatives.splice(index, 1);
		this.props.changeCampaignData(data);
		this.setState({ update: true });
	}

	onChangeAlternativa(e, index) {
		let data = this.props.campaignData;
		data.filter_survey.alternatives[index][e.target.name] = e.target.value;

		this.props.changeCampaignData(data);
		this.setState({ update: true });
	}

	render() {
		const { campaignData } = this.props;
		let item = campaignData.filter_survey;

		return (
			<fieldset className="mt-2">
				<h4 className="card-title">{translate('Pergunta Filtro')}</h4>

				<fieldset className="mb-4" key="filter_survey">
					<div className="form-row">

						<div className="form-group col-9 offset-1">
							<label>{translate('Título da Pergunta')}</label>
							<input type="text" className="form-control" name="description" onChange={(val) => { this.onChange(val) }} value={item.description} />
						</div>
					</div>
					<div className="col-11 offset-1">
						{
							item.alternatives.map((alternative, e) => {
								return (
									<div className="form-row" key={"alternative_" + e}>
										<div className="form-group col-md-7">
											<label>{translate('Resposta')} {(e + 1)}</label>
											<input type="text" className="form-control" name="alternative" disabled={item.answers > 0 ? true : false} onChange={(val) => { this.onChangeAlternativa(val, e) }} value={alternative.alternative} />
										</div>

										<div className="form-group col-md-2">
											<label>{translate('Avançar')}</label>
											<select className="form-control" name="correct" disabled={item.answers > 0 ? true : false} onChange={(val) => { this.onChangeAlternativa(val, e) }} value={alternative.correct}>
												<option value="0">{translate('Não')}</option>
												<option value="1">{translate('Sim')}</option>
											</select>
										</div>
										<div className="form-group col-md-1 align-bottom d-flex mb-2">
											{(item.alternatives.length > 1 && item.answers === 0 ? //antes == depois ===
												<button type="button" className="btn btn-danger btn-sm  btn-circle align-self-end" onClick={() => this._removeAlternativa(e)}><i className="fa fa-minus"></i></button>
												: null
											)}
										</div>
									</div>
								)
							})
						}
						<div className="row">
							{(item.alternatives.length < 5 && item.answers === 0 ? //antes == depois ===
								<div className="col-sm-3">
									<button type="button" className="btn btn-success btn-round" onClick={() => { this._addAlternativa() }}><i className="fa fa-plus"></i> {translate('Adicionar')}</button>
								</div> : null

							)}
						</div>
					</div>
				</fieldset>


			</fieldset>
		)
	}
}

const mapStateToProps = store => ({
	campaignData: store.campaign.current,
	surveyTypes: store.campaign.survey_types,
	changeCampaignData: changeCampaignData,
})

const mapDispatchToProps = dispatch =>
	bindActionCreators({ changeCampaignData }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(FilterSurvey)
