import React, { Component } from 'react'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import { changeCampaignData, getSurveyTypes } from '../../../actions/campaignActions'
import { translate } from '../../../components/Translate'

export class Surveys extends Component {
	constructor(props) {
		super(props);
		this.onChange = this.onChange.bind(this);
		this.onChangeAlternativa = this.onChangeAlternativa.bind(this);
	}

	async componentDidMount() {
		await this.props.getSurveyTypes();
		this.setState({ "update": true });
	}

	onChange(e, index) {
		let data = this.props.campaignData
		data.surveys[index][e.target.name] = e.target.value;

		this.props.changeCampaignData(data);
		this.setState({ update: true });
	}

	_novoItem = () => {
		let data = this.props.campaignData
		data.surveys.unshift({
			"campaign_survey_id": null,
			"sort": 0,
			"answers": 0,
			"survey_type_id": "",
			"description": "",
			"alternatives_random": "",
			"alternatives_types": "",
			"alternatives": [
				{ "alternative": "", "correct": "1" }
			]
		});
		this.props.changeCampaignData(data);
		this.setState({ update: true });
	}

	_addAlternativa = (survey_id) => {
		let data = this.props.campaignData
		data.surveys[survey_id].alternatives.push({ alternative: '', correct: "0" })
		this.props.changeCampaignData(data);
		this.setState({ update: true });
	}

	_removeAlternativa = (survey_id, index) => {
		let data = this.props.campaignData
		data.surveys[survey_id].alternatives.splice(index, 1);
		this.props.changeCampaignData(data);
		this.setState({ update: true });
	}

	onChangeAlternativa(e, survey_id, index) {
		let data = this.props.campaignData
		data.surveys[survey_id].alternatives[index][e.target.name] = e.target.value;

		this.props.changeCampaignData(data);
		this.setState({ update: true });
	}

	_removeItem = index => {
		let data = this.props.campaignData
		data.surveys.splice(index, 1);
		this.props.changeCampaignData(data);
		this.setState({ update: true });
	}

	render() {
		const { campaignData, surveyTypes } = this.props;
		return (
			<fieldset className="mt-2">
				<h4 className="card-title">{translate('Perguntas')}</h4>
				<button type="button" className="btn btn-success btn-round mb-4" onClick={this._novoItem}><i className="fa fa-plus"></i> {translate('Nova Pergunta')}</button>
				{campaignData.surveys.map((item, i) => {
					return (
						<fieldset className="mb-4" key={"survey_" + i}>
							<div className="form-row">
								<div className="form-group col-md-1">
									<label>{translate('Ordem')}</label>
									<input type="text" className="form-control" name="sort" onChange={(val) => { this.onChange(val, i) }} value={item.sort} />
								</div>

								<div className="form-group col-md-4">
									<label>{translate('Título da Pergunta')}</label>
									<input type="text" className="form-control" name="description" disabled={item.answers > 0 ? true : false} onChange={(val) => { this.onChange(val, i) }} value={item.description} required />
								</div>

								<div className="form-group col-md-2">
									<label>{translate('Tipo')}</label>
									<select className="form-control" name="survey_type_id" disabled={item.answers > 0 ? true : false} onChange={(val) => { this.onChange(val, i) }} value={item.survey_type_id}>
										<option value="">{translate('Selecione um tipo')}</option>
										{
											surveyTypes.map(survey_type => {
												return (
													<option value={survey_type.survey_type_id} key={survey_type.survey_type_id}>{survey_type.description}</option>
												)
											})
										}
									</select>
								</div>

								<div className="form-group col-md-2">
									<label>{translate('Opções Aleatórias?')}</label>
									<select className="form-control" name="alternatives_random" disabled={item.answers > 0 ? true : false} onChange={(val) => { this.onChange(val, i) }} value={item.alternatives_random} required>
										<option value="">{translate('Selecione')}</option>
										<option value="0">{translate('Não')}</option>
										<option value="1">{translate('Sim')}</option>
									</select>
								</div>

								<div className="form-group col-md-2">
									<label>{translate('Tipo de Opções')}</label>
									<select className="form-control" name="alternatives_types" disabled={item.answers > 0 ? true : false} onChange={(val) => { this.onChange(val, i) }} value={item.alternatives_types} required>
										<option value="">{translate('Selecione')}</option>
										<option value="radio">{translate('Única Escolha')}</option>
										<option value="checkbox">{translate('Multiplas Escolhas')}</option>
									</select>
								</div>

								<div className="form-group col-md-1">
									<label>{translate('Fonte')}</label>
									<select className="form-control" name="font_size" onChange={(val) => { this.onChange(val, i) }} value={item.font_size}>
										<option value="normal">{translate('Normal')}</option>
										<option value="small">{translate('Pequena')}</option>
										<option value="extra_small">{translate('Muito Pequena')}</option>
									</select>
								</div>

								{campaignData.surveys.length > 1 && item.answers === 0 ? //antes == depois ===
									<div className="form-group col-md-1 align-bottom d-flex mb-2">

										<button type="button" className="btn btn-danger btn-sm btn-circle align-self-end" onClick={() => this._removeItem(i)}><i className="fa fa-minus"></i></button>

									</div>
									: null
								}
							</div>
							<div className="col-11 offset-1">
								{
									item.alternatives.map((alternative, e) => {
										return (

											<div className="form-row" key={"alternative_" + e}>
												<div className="form-group col-md-7">
													<label>{translate('Resposta')} {(e + 1)}</label>
													<input type="text" className="form-control" name="alternative" disabled={item.answers > 0 ? true : false} onChange={(val) => { this.onChangeAlternativa(val, i, e) }} value={alternative.alternative} required />
												</div>



												<div className="form-group col-md-2">
													<label>{translate('Opção Correta?')}</label>
													<select className="form-control" name="correct" disabled={item.answers > 0 ? true : false} onChange={(val) => { this.onChangeAlternativa(val, i, e) }} value={alternative.correct}>
														<option value="0">{translate('Não')}</option>
														<option value="1">{translate('Sim')}</option>
													</select>
												</div>
												<div className="form-group col-md-1 align-bottom d-flex mb-2">
													{(item.alternatives.length > 1 && item.answers === 0 ? //antes == depois ===
														<button type="button" className="btn btn-danger btn-sm  btn-circle align-self-end" onClick={() => this._removeAlternativa(i, e)}><i className="fa fa-minus"></i></button>
														: null
													)}
												</div>
											</div>
										)
									})
								}
								<div className="row">
									{(item.alternatives.length < 5 && item.answers === 0 ? //antes == depois ===
										<div className="col-sm-3">
											<button type="button" className="btn btn-success btn-round" onClick={() => { this._addAlternativa(i) }}><i className="fa fa-plus"></i> {translate('Adicionar')}</button>
										</div> : null

									)}
								</div>
							</div>
						</fieldset>
					)
				})}

			</fieldset>
		)
	}
}

const mapStateToProps = store => ({
	campaignData: store.campaign.current,
	surveyTypes: store.campaign.survey_types,
	changeCampaignData: changeCampaignData,
	getSurveyTypes: getSurveyTypes
})

const mapDispatchToProps = dispatch =>
	bindActionCreators({ changeCampaignData, getSurveyTypes }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Surveys)
