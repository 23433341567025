import React, { Component } from 'react'
import Header from '../../../components/Header'
import Voltar from '../../../components/Voltar'
import Campaign from './campaign'
import Contacts from './contacts'
import Creatives from './creatives';
import Surveys from './surveys';
import Sites from './sites';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import { changeCampaignData, addCampaign, getCampaign, updateCampaign, resetCampaignData, uploadVideo } from '../../../actions/campaignActions'
import { translate } from '../../../components/Translate'
import FilterSurvey from './filterSurvey'

export class FormCampanha extends Component {

	constructor(props) {
		super(props);
		this.state = {
			rando: 1,
			usersDeleted: []

		}
		if (!localStorage.getItem('bfToken')) {
			window.location.href = '/';
		}


		this.updateMyState = this.updateMyState.bind(this)

	}

	updateMyState(users) {
		// console.log("update myState")
		this.setState({
			"usersDeleted": users,
			"rando": Math.random(0, 100000),
		})
	}

	_save = async (e) => {
		e.preventDefault();
		let data = this.props.campaignData;
		if (this.props.match) {
			// console.log("Caiu aqui no save")
			// console.log(data['campaign_video']);
			if (data['campaign_types_view_id'] != '1' && typeof data['campaign_video'] != 'string' && data['campaign_video'] != null) {
				let upload_data = {
					'campaign_video': data['campaign_video']
				}
				let response = await uploadVideo(upload_data)
				if (response.data['status'] == 1) {
					data['campaign_video'] = response.data['url'];
				} else {
					// console.log("Caiu aqui no save")
					alert(response.data['msg'])
				}
			}
			this.props.updateCampaign(data, this.props.match.params.id);
		} else {
			if (data['campaign_types_view_id'] != '1' && typeof data['campaign_video'] != 'string' && data['campaign_video'] != null) {
				// console.log(data)
				let upload_data = {
					'campaign_video': data['campaign_video']
				}
				let response = await uploadVideo(upload_data)
				if (response.data['status'] == 1) {
					data['campaign_video'] = response.data['url'];
				} else {
					alert(response.data['msg'])
				}
			}
			await this.props.addCampaign(data);
		}

		this.setState({ "update": true })
	}

	async componentDidMount() {
		if (this.props.match) {
			let campaign_id = this.props.match.params.id
			if (campaign_id) {
				await this.props.getCampaign(campaign_id);
				this.setState({ "update": true })
				gtag('event', 'page_view', {
					'page_title': 'Editar Pesquisa ' + this.props.campaignData.name,
					'page_location': window.location.href
				});
			}
		} else {
			await this.props.resetCampaignData();
			this.setState({ "update": true })
			gtag('event', 'page_view', {
				'page_title': 'Nova Pesquisa',
				'page_location': window.location.href
			});
		}


	}

	render() {
		const { rando, usersDeleted } = this.state;
		const { loading } = this.props;
		const { campaignData } = this.props;
		// console.log(campaignData) 
		return (
			<div>
				<Header />
				<div className="alert alert-dark m-0 p-0">
					<div className="container p-0">
						<ol className="breadcrumb">
							<li className="breadcrumb-item"><a href="/campanhas/">{translate('Pesquisas')}</a></li>
						</ol>
					</div>
				</div>

				<div className="container" style={{ paddingBottom: '60px' }}>
					<h2 className="title m-0 h3"> {translate('Editar Pesquisa')}</h2>
					<form onSubmit={this._save}>
						<div className="card">
							<div className="card-header">
								<h4 className="card-title">{translate('Pesquisa')}</h4>
							</div>
							<div className="card-body">
								<Campaign update={rando} updateMyState={this.updateMyState} />
								<Contacts update={rando} deletedUser={usersDeleted} updateMyState={this.updateMyState} />

								{['2', '4'].indexOf(campaignData['campaign_types_view_id']) > -1 ?
									<FilterSurvey updateMyState={this.updateMyState} /> : null
								}
								<Surveys />
								{['1', '5'].indexOf(campaignData['campaign_types_view_id']) > -1 ?
									<div>
										<Creatives />
										<Sites />
									</div>
									:
									null
								}
							</div>
						</div>
						<div className="row justify-content-center">
							<div className="col-sm-3">
								{loading ?
									<button className="btn btn-success btn-block btn-round disabled" disabled><span className="fa fa-spin fa-refresh"></span></button>
									:
									<button className="btn btn-success btn-block  btn-round">{translate('Salvar Pesquisa')}</button>
								}
							</div>
						</div>

					</form>
				</div>
			</div>
		)
	}
}

const mapStateToProps = store => ({
	campaignData: store.campaign.current,
	changeCampaignData: changeCampaignData,
	loading: store.campaign.loading_save,
	addCampaign: addCampaign,
	updateCampaign: updateCampaign,
	resetCampaignData: resetCampaignData


})

const mapDispatchToProps = dispatch =>
	bindActionCreators({ changeCampaignData, addCampaign, getCampaign, updateCampaign, resetCampaignData }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(FormCampanha)
